import {
  wrSystemConfig,
  SectionWheels,
  supportsDarkMode,
} from '@wheelroom/core'
import { vdbraakmaasLight } from '../themes/vdbraakenmaas/vdbraakenmaas'

export const sectionWheels: SectionWheels = {
  defaultThemeId: supportsDarkMode() ? 'vdbraakmaasLight' : 'vdbraakmaasLight',
  themes: {
    vdbraakmaasLight,
  },
  wrSystemConfig,
}
