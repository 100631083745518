import { baseColors } from './base-colors'

export const light = {
  ...baseColors,

  heading: baseColors.ocean,
  text: baseColors.ocean,
  link: baseColors.olive,
  border: baseColors.silver,
  outline: baseColors.olive,

  dividerBg: baseColors.ghost,

  skipToContentBg: baseColors.ocean,
  skipToContentText: baseColors.white,
  skipToContentOutline: baseColors.olive,

  sectionBg: baseColors.white,
  sectionText: baseColors.ocean,
  sectionBorder: baseColors.silver,

  buttonPrimaryBg: baseColors.olive,
  buttonPrimaryBgState: baseColors.oliveShade,
  buttonPrimaryBorder: baseColors.olive,
  buttonPrimaryText: baseColors.white,

  buttonSecondaryBg: 'transparent',
  buttonSecondaryBorder: baseColors.metal,
  buttonSecondaryBorderState: baseColors.bullet,
  buttonSecondaryText: baseColors.ghost,

  cardBorder: baseColors.silver,
  cardShadow: baseColors.silver,

  modalBg: baseColors.white,
  modalBorder: baseColors.bullet,
  modalShadow: baseColors.bullet,

  iconColor: baseColors.olive,
  iconColorState: baseColors.oliveShade,

  codeBg: baseColors.skyblue,
}
