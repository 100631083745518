import {
  // breakpointsPreset,
  mapsPreset,
  scalesPreset,
  WrSystemTheme,
} from '@wheelroom/core'

export const wrSystemTheme: WrSystemTheme = {
  // ...breakpointsPreset,
  breakpoints: ['48em', '49.9375em', '80em', '90.0625em'],
  ...mapsPreset,
  ...scalesPreset,
  colorMap: {},
}
