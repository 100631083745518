import { SectionWheelTheme } from '@wheelroom/core'
import { wrSystemTheme } from '../wr-system-theme'
import { elementNcss } from './element-ncss/element-ncss'
import { styles } from './ncss-trees/styles'
import { light } from './colors/light'
import { data } from './data/data'

export const vdbraakmaasLight: SectionWheelTheme = {
  themeName: 'vdbraakmaas light',
  elementNcss,
  styles,
  data,
  wrSystemTheme: {
    ...wrSystemTheme,
    colorMap: light,
    fontMap: {
      text: `"Lato",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`,
      heading: `"Lato",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`,
      display: `"Lato",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`,
      branding: `"Lato",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`,
    },
  },
}
